<template>
  <div class="time-line-img-box">
    <img :src="require('@/assets/images/TimeLineImg/'+ Number(LineNumber) + '.jpg')" alt="蚂蚁写作">
  </div>
</template>
<script>
export default {
  props: ['LineNumber'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
<style scoped lang="less">
.time-line-img-box{
  width: 100%;
  max-width: 440px;
  height: 100%;
  background-color: #eefbff;
  overflow: hidden;
  img{
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .time-line-img-box{
    display: none;
  }
}
</style>
